@import "bootstrap/dist/css/bootstrap.min.css";
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";

@keyframes p-progress-spinner-color {
	0%, 100% {
		stroke: #e08484;
	}
}

body {
	overflow-x: hidden;
}