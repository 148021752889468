@media (max-width: 991px) {
	.warning-bar {
		justify-content: space-between;
	}

	.layout-topbar {
		justify-content: space-between;
	}

	.layout-topbar .layout-menu-button {
		margin-left: 0;
		order: 1;
	}

	.layout-topbar .layout-topbar-logo {
		order: 2;
		width: auto;
	}

	.layout-topbar .layout-topbar-menu {
		animation: scalein 0.15s linear;
		background-color: #FFFFFF;
		box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
		display: none;
		flex-direction: column;
		margin-left: 0;
		min-width: 15rem;
		padding: 0.5rem;
		position: absolute;
		right: 2rem;
		top: 5rem;
	}

	.layout-topbar .layout-topbar-menu .layout-topbar-button {
		display: flex;
		height: auto;
		justify-content: flex-start;
		margin-left: 0;
		padding: 1rem;
		width: 100%;
	}

	.layout-topbar .layout-topbar-menu .layout-topbar-button i {
		font-size: 1rem;
		margin-right: 0.5rem;
	}

	.layout-topbar .layout-topbar-menu .layout-topbar-button span {
		display: block;
		font-weight: medium;
	}

	.layout-topbar .layout-topbar-menu .layout-topbar-menu-mobile-active {
		display: block
	}

	.layout-topbar .layout-topbar-menu-button {
		display: inline-flex;
		margin-left: 0;
		order: 3;
	}

	.p-menu.p-component {
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		height: 100vh;
		left: 0;
		top: 0;
		transform: translateX(-100%);
	}
}

.customer-first-login.p-dialog {
	overflow: hidden;
}

.warning-bar {
	align-items: center;
	background-color: #F7665E;
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0 ,0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
	display: flex;
	height: 2rem;
	left: 0;
	position: fixed;
	text-align:center !important;
	width: 100%;
	z-index: 997;
}

.warning-bar-text {
	-webkit-font-smoothing: antialiased;
	color: #FFFFFF;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	font-size: 1rem;
	width:100%;
	text-align: center !important;
}

.warning-bar-text a {
	color: #E9ECEF;
}
.warning-bar-text a:hover{
	color: #FFFFFF;
}

.layout-topbar {
	align-items: center;
	background-color: #b03232;
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0 ,0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
	display: flex;
	height: 4rem;
	left: 0;
	padding: 0 2rem;
	position: fixed;
	top: 0;
	transition: left 0.2s;
	width: 100%;
	z-index: 997;
}

.layout-topbar .layout-menu-button {
	margin-left: 0rem;
}

.layout-topbar .layout-topbar-button {
	align-items: center;
	color: #FFFFFF;
	cursor: pointer;
	display: inline-flex;
	height: 3rem;
	justify-content: center;
	position: relative;
	transition: background-color 0.2s;
	width: 3rem;
}

.layout-topbar .layout-topbar-button:hover {
	background-color: #E9ECEF;
	color: #495057;
}

.layout-topbar .layout-topbar-button:focus, .layout-topbar .layout-topbar-logo:focus {
	box-shadow: 0 0 0 0.2rem #e08484;
	outline: 0 none;
	outline-offset: 0;
	transition: box-shadow 0.2s;
}

.layout-topbar .layout-topbar-button i {
	font-size: 1.5rem;
}

.layout-topbar .layout-topbar-button span {
	font-size: 1rem;
	display: none;
}

.layout-topbar .layout-topbar-logo {
	display: flex;
	align-items: center;
	color: #FFFFFF;
	font-size: 1.5rem;
	font-weight: 500;
	width: 300px;
	margin-left: 2rem;
}

.layout-topbar .layout-topbar-logo img {
	height: 2.5rem;
	margin-right: .5rem;
}

.layout-topbar .layout-topbar-menu {
	margin: 0 0 0 auto;
	padding: 0;
	list-style: none;
	display: flex;
}

.layout-topbar .layout-topbar-menu .layout-topbar-button {
	margin-left: 1rem;
}

.layout-topbar .layout-topbar-menu-button {
	display: none;
}

.layout-topbar .layout-topbar-menu-button i {
	font-size: 1.25rem;
}

.p-menu.p-component {
	background-color: #FFFFFF;
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
	height: calc(100vh - 8rem);
	left: 2rem;
	overflow-y: auto;
	padding: 0.5rem;
	position: fixed;
	top: 6rem;
	transition: left 0.2s, -webkit-transform 0.2s;
	transition: transform 0.2s, left 0.2s;
	transition: transform 0.2s, left 0.2s, -webkit-transform 0.2s;
	user-select: none;
	width: 300px;
	z-index: 999;
}

.p-menuitem-link.active *:not(.p-badge) {
	color: #e08484 !important;
}