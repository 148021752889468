@import url("https://fonts.cdnfonts.com/css/gilroy-bold");
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}


body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	margin: 0;
}

.fade.modal-backdrop.show {
	z-index: 750 !important;
}

.fade.modal.show {
	z-index: 800 !important;
}

div.card { padding-top: 1.5rem; }

div.field { margin-bottom: 10px; }

.p-calendar .p-inputtext {
	padding-left: 0.25rem;
	padding-right: 0.25rem;
}

.list-none {
    list-style: none !important;
}

label { display: block; }

.row-badge {
	border-radius: 2px;
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 0.3px;
	padding: 0.25em 0.5rem;
	text-transform: uppercase;
}

.product-image {
	border-radius: 50px !important;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	width: 100px;
}

.row-badge {
	border-radius: 2px;
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 0.3px;
	padding: 0.25em 0.5rem;
	text-transform: uppercase;
}

.status-hidden {
	background: #ffcdd2;
	color: #c63737;
}

.status-pending {
	background: #feedaf;
	color: #8a5340;
}

.status-public {
	background: #c8e6c9;
	color: #256029;
}

.status-accepted {
	background: #c8a5f9;
	color: #450099;
}

.warningLabel {
	background: #ffcdd2;
	color: #c63737;
	padding:20px;
	border-radius:20px !important;
}

h5 { padding-bottom: 1rem; padding-left: 1.5rem; }

.p-tabview .pi {
	margin-right: 10px;
}

div.dataTableWrapper {
	height: calc(100vh - 20rem);
	width: 100%;
	overflow: scroll;
}

.reportDateFields { padding: 0 25px; }
.reportDateFields label { margin: 0 10px 0 0; }