.customer-my-account button, .customer-my-account input {
	border-radius: 10px !important;
}

.customer-my-account input {
	padding-left: 10px !important;
	padding-right: 10px !important;
}

.customer-my-account input:disabled {
	background-color: #B5B5B5;
}

.chef-change-password.p-dialog {
	overflow: hidden;
}

.chef-change-password-body input, .chef-my-account button, .chef-my-account input {
	border-radius: 10px !important;
}